<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Pages" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <div>
          <md-button class="md-primary mx-4" :to="{ name: 'Pages Section' }">
            View Sections
          </md-button>
          <md-button class="md-primary" :to="{ name: 'Pages Content Create' }">
            Add Page
          </md-button>
        </div>
      </div>
      <md-table
        v-model="pages.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start"></div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-icon>search</md-icon>
              <md-input placeholder="Search " v-model="search" />
            </md-field>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N">{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Title">
            <div>
              <a
                class="d-flex"
                style="align-items: center"
                :href="item.page_url"
                target="_blank"
              >
                <span>{{ item.title }}</span>
                <md-icon>link</md-icon>
              </a>
            </div>
          </md-table-cell>

          <md-table-cell md-label="Tag">{{ item.slug }}</md-table-cell>

          <md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.status == 'live',
                grey: item.status == 'draft',
              }"
            >
              {{ item.status }}
            </div></md-table-cell
          >
          <md-table-cell md-label="Actions" md-numeric>
            <div class="table-actions">
              <md-button
                :disabled="updating"
                md-menu-trigger
                class="bggreen btn-sm"
                :to="{ name: 'Pages Content Edit', params: { id: item.id } }"
                >Edit</md-button
              >
              <md-button
                :disabled="updating"
                md-menu-trigger
                class="bggreen btn-icon"
                @click="remove(item.id)"
                ><i class="material-icons">close</i></md-button
              >
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="pages.mdPage"
        :records="pages.mdCount"
        :per-page="pages.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("pages");
export default {
  name: "Pages",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      time: null,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getPages", "deletePage"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getPages(data);
    },
    async submitSearch() {
      await this.getPages({ keyword: this.search });
    },

    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async remove(id) {
      try {
        const conf = confirm("Are you sure?");
        if (conf) {
          this.deleting = true;
          await this.deletePage(id);
          this.deleting = false;
          this.toast("success", "Page Deleted");
        }
      } catch (err) {
        this.deleting = false;

        this.toast("error", "Opps, something went wrong");
      }
    },
  },
  created() {},
  mounted() {
    this.getPages();
  },
  computed: {
    ...mapGetters(["pages", "loading"]),
  },
};
</script>
<!-- jkfj -->

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.table-actions {
  width: 100%;
  display: flex;
  justify-content: end;
}
</style>
